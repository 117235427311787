
export default {
  props: {
    type: {
      type: String,
      default: "",
    },
    uuid: {
      type: String,
      default: "",
    }
  }
}
