<template lang="pug">
include Web.pug
</template>

<script type="text/javascript">
import Pubsub from 'pubsub-js'
import ProfileItemMixin from '@/components/profile_items/Mixin.js'

const label = {
  musicLink: "Enlace de música",
  videoLink: "Vídeo",
  link: "Enlace",
  contact: "Dato de contacto",
  form: "Formulario",
  web: "Enlace web",
}

export default {
  name: "Web",
  mixins: [ProfileItemMixin],
  computed: {
    validUrl() {
      const value = this.value
      if (!value || !value.length) return true
      return /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/.test(value)
    }
  },
  data() {
    return {
      label: "",
      value: "",
      title: "",
      enabled: true,
    }
  },
  created() {
    this.label = label[this.type]

    Pubsub.subscribe('vcard.initial_data', (event, item) => {
      if (this.uuid === item.uuid) {
        this.value = item.value
        this.title = item.title
        this.enabled = item.enabled
      }
    })
  },
  methods: {
    selfDelete() {
      Pubsub.publish("vcard.delete_item", this.uuid)
    },
    sync() {
      Pubsub.publish("vcard.object_update", {
        uuid: this.uuid,
        type: this.type,
        title: this.title,
        value: this.value,
        enabled: this.enabled,
      })
    }
  },
  watch: {
    value() {
      this.sync()
    },
    title() {
      this.sync()
    },
    enabled() {
      this.sync()
    },
  }
}
</script>

<style scoped lang="scss">
@import './../../variables';
@import './../ProfileVCard';
@import './../ProfileVCardLinks';
</style>
