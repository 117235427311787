<template lang="pug">
include ProfileViewSocialIcons.pug
</template>

<script type="text/javascript">
export default {
  name: "ProfileViewSocialIcons",
  props: {
    vcard: {
      type: Object,
      default() {
        return {}
      }
    }
  },
}
</script>

<style scoped lang="scss">
@import './../variables';
@import './ProfileView';
</style>
