<template lang="pug">
.wrapper-profile-block(v-if="item && item.enabled")
  include ProfileBlock.pug
</template>

<script type="text/javascript">
import _ from 'underscore'

const validUrl = value => {
  if (!value || _.isEmpty(value)) return false
  return /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/.test(value)
}

export default {
  name: "ProfileBlock",
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      opened: false,
      youtubeIframe: null,
    }
  },
  computed: {
    valid() {
      return validUrl(this.item.value)
    },
    isYoutube() {
      return this.item.value.includes("youtube")
    },
    isVimeo() {
      return this.item.value.includes("vimeo")
    }
  },
  created() {
    const isYoutube = this.isYoutube
    if (isYoutube) {
      this.buildYoutube()
    }
  },
  methods: {
    toggleOpen() {
      this.opened = !this.opened
    },
    buildYoutube() {
      // https://www.youtube.com/watch?v=FRelb9_BKrg
      const youtubeUrl = this.item.value
      const urlSplit = _.first(_.last(youtubeUrl.split('?v=')).split('#'))
      this.youtubeIframe = `https://www.youtube.com/embed/${urlSplit}`
    },
  }
}
</script>

<style scoped lang="scss">
@import './../variables';

@import './ProfileView';
</style>
