import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)


let userEmail = null;

export default new Vuex.Store({
  state: {
    userEmail,
  },
  getters: {
  },
  mutations: {
    set_userEmail: (state, email) => {
      state.userEmail = email;
    },
  },
  actions: {
  },
  modules: {
  }
})
