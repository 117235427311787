<template lang="pug">
include ProfileItem.pug
</template>

<script type="text/javascript">
import Web from '@/components/profile_items/Web'

export default {
  name: "ProfileItem",
  components: {
    Web,
  },
  props: {
    element: {
      type: Object,
      default() {
        return {}
      }
    },
  },
}
</script>
