import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'font-awesome/css/font-awesome.min.css'
import Notifications from 'vue-notification'
import VueBus from 'vue-bus'
import axios from 'axios'
import VueCookies from 'vue-cookies'
import { Settings, settings } from '@/settings'

Vue.config.productionTip = false
Vue.use(Notifications)
Vue.use(VueBus)
Vue.use(VueCookies)
Vue.use(Settings)

// Vue Cookies Configuration
Vue.$cookies.config("7D", "/", settings.cookiesDomain, true, "");

// Axios Defaults: Should be applied to every request.
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.timeout = 10000;
axios.defaults.withCredentials = true;
axios.interceptors.response.use(
  (response) => { return response },
  (error) => {
    if (error.response.status == 403) {
      router.push(settings.iamBaseUrl);
    }
    return Promise.reject(error);
  }
);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
