<template lang="pug">
  .resource-view-wrapper(:class="'context-' + context")
    include ExploreLinks.pug
</template>

<script>
import Pubsub from 'pubsub-js'
import { mapState } from 'vuex'

export default {
  name: 'ExploreLinks',
  props: [],
  components: {
  },
  computed: mapState([
    "context",
    "token",
  ]),
  data() {
    return {}
  },
  created() {},
  methods: {
    selectOption(option) {
      Pubsub.publish("vcard.new_item", option)
    },
    closeModal() {
      Pubsub.publish("vcard.close_new_item", null)
    }
  }
}
</script>

<style lang="scss">
@import './../../variables';
@import './../../variables_colreps';

@import 'ExploreLinks';
</style>
