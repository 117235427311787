'use strict'

import axios from 'axios'
import { settings } from '@/settings'

const API_BASE = settings.apiBaseUrl;
const IAM_API_BASE = settings.iamApiUrl;


class Api {
  getApiUrl() {
    return API_BASE
  }
  getIamApiUrl() {
    return IAM_API_BASE
  }
  getVcard(id) {
    return axios({
      url: `${API_BASE}/vcard/${id}/`,
      method: 'get',
    })
  }
  getVcardProfile(name) {
    return axios({
      url: `${API_BASE}/vcard/profile/${name}/`,
      method: 'get',
    })
  }
  updateVcard(id, params, token) {
    return axios({
      data: params,
      url: `${API_BASE}/vcard/${id}/`,
      method: 'post',
    })
  }
  contactVcard(id, params) {
    return axios({
      data: params,
      url: `${API_BASE}/vcard/contact/${id}/`,
      method: 'post',
    })
  }
  me(userEmail) {
    return axios({
      url: `${API_BASE}/user/${userEmail}`,
      method: 'get',
    })
  }
  checkJwtToken(jwtToken) {
    return axios({
      data: {token: jwtToken},
      url: `${IAM_API_BASE}/accounts/verify-jwt/`,
      method: 'post',
    })
  }
  uploadAvatar(uuid, avatarImage) {
    let formData = new FormData();
    formData.append('file', avatarImage);
    return axios({
      data: formData,
      url: `${API_BASE}/vcard/avatar/${uuid}/`,
      method: 'post',
      headers: {'Content-Type': 'multipart/form-data'},
    })
  }
}

export default new Api()
