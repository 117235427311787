<template lang="pug">
    #app
        //- .topbar-wrapper.topbar-wrapper-updated(v-if="visible")
        //-     topbar
        router-view(:key="$route.fullPath")
        //- application-footer
        .notification-wrapper
            notifications(group="esreps", position="top center")
</template>

<script>
// import Promise from 'promise-polyfill';
import Topbar from "@/components/topbar/Topbar";
// import Uploader from "@/components/uploader/Uploader";
import ApplicationFooter from "@/components/ApplicationFooter";
// import Api from "@/components/services/Api";

export default {
  name: 'App',
  components: {
    Topbar,
    ApplicationFooter,
  },
  data() {
    return {
      visible: false,
    }
  },
  created() {
    this.applyVisibility(this.$route.name)
  },
  methods: {
    applyVisibility(name) {
      const notAllowedRoutes = [
        'ProfileView',
        'ProfileVCardLinks',
        'ProfileVCardAppearance',
        'ProfileVCardSocial',
        'ExploreLinks',
      ]

      this.visible = !notAllowedRoutes.includes(name)
    }
  },
  watch: {
    $route: function(to) {
      const toName = to.name
      this.applyVisibility(toName)
    }
  }
}
</script>

<style lang="scss">
@import "reset";
@import "variables";
@import "variables_colreps";
@import "variables_mexreps";
@import "tooltip";
@import "styles/bars";
@import "styles/content-format";
@import "styles/tips"; /* estilos de tips de recursos y de descuentos */
@import "styles/blocks"; /* estilos de bloques de blog y de recursos, por ejemplo */
@import "styles/banners";
@import "styles/testimonials";
@import "styles/common_forms"; /* estilos de formularios */
@import "styles/edit_profile"; /* estilos de edición de el perfil de entrenador */
@import '../node_modules/slick-carousel/slick/slick';


body, html {
    margin: 0;
    padding: 0;
}

body {
    @include breakpoint($breakpoint-tablet) {
        box-sizing: initial!important;
    }
}

#app {
    font-family: $font-primary;
    font-weight: 400;
    color: #000;
    box-sizing: border-box;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    overflow-x: hidden;
}

#app.app-colreps {
    font-family: $font-primary-colreps;
}

#app.app-mexreps {
    font-family: $font-primary-mexreps;
}

p {
    line-height: normal;
}

.secondary {
    font-family: $font-secondary!important;
}

.hidden-tablet {
  @include breakpoint($breakpoint-tablet) {
    display: none;
  }
}

.only-tablet {
  display: none;
  @include breakpoint($breakpoint-tablet) {
    display: block;
  }
}

.hidden-mobile {
  @include breakpoint($breakpoint-xmobile) {
    display: none;
  }
}

.only-mobile {
  display: none;
  @include breakpoint($breakpoint-xmobile) {
    display: block;
  }
}

.hidden-content {
    display: none !important;
}

.more-link {
    color: $color-primary;
    float: right;
}

.button-wrapper.right {
    text-align: right;
    padding: 1em 1em 1.5em 1em;
}

.button-yellow {
  font-size: 0.75em;
  font-weight: bold;
  letter-spacing: 1.05px;
  padding: 0.6em 3em;
  margin-top: 1em;
  color: $color-black;
  background-color: $color-primary;
  border: none;
  text-transform: uppercase;

  &:hover {
      color: $color-white;
  }
}

.context-colreps {
    .button-yellow {
        background-color: $color-accent-colreps;
        color: $color-white-colreps;
    }
}

.context-mexreps {
    .button-yellow {
        background-color: $color-accent-mexreps;
        color: $color-white-colreps;
    }
}

.page, .content {
    font-family: $font-primary;
    font-size: 1.25em;
    line-height: 1.45em;

    p, div[align="justify"] {
        margin-bottom: 0.5rem;
        text-align: justify;
    }
    strong, b {
        font-weight: 700;
    }
    ul, ol {
        margin: $gutter*2 0;
        padding-left: $gutter*3;
    }
    li {
        list-style-type: disc;
        padding: 2px 0;
    }
}

.context-colreps {
    .page, .content {
        font-family: $font-primary-colreps;
    }
}

.context-mexreps {
    .page, .content {
        font-family: $font-primary-mexreps;
    }
}

b {
    font-weight: bold;
}

em, i {
    font-style: italic;
}

.page {
    h3 {
        margin: 1.5rem 0 1rem 0;
    }

    hr {
        margin: 2.8rem 0;
    }

    b {
        padding-bottom: 1.3rem;
        // display: block;
    }

    img {
        max-width: 100%;
    }
}

.page-intro {
    color: $color-primary;
}

.bg-primary {
    background-color: $color-primary!important;
}
.fg-primary {
    color: $color-primary!important;
}
.bg-accent {
    background-color: $color-accent!important;
}
.fg-accent {
    color: $color-accent!important;
}

.desktop-only {
    @include breakpoint($breakpoint-tablet) {
        display: none;
    }
}

.small-text {
    font-size: 0.85em;
}

.form-text {
    display: block;
    width: 100%;
    text-align: left!important;
    @include clearfix;
    padding-left: 30px;
    margin-top: -17px;

    em {
        color: $color-black;
        text-decoration: underline;
    }

    a {
        color: $color-black;
    }
}

.page-wrapper {
  margin-left: 0;
  margin-right: 0;

  .row {
    margin-left: 0;
    margin-right: 0;
  }
}

.page-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 15px;

  h1 {
    font-size: 2.875em;
    font-weight: bold;

    @include breakpoint($breakpoint-tablet) {
      font-size: 1.9rem;
    }
  }

  .breadcrumbs {
    text-transform: uppercase;
    font-size: 11px;
    color: #6d7278;

    @include breakpoint($breakpoint-tablet) {
        display: none;
    }

    a {
        color: #6d7278;

        &:hover {
            color: $color-black;
        }
    }
  }
}

.page-heading.smaller {
    h1 {
        font-size: 2em;
        max-width: 60%;
        line-height: 1.3em;

        @include breakpoint($breakpoint-tablet) {
            max-width: 100%;
            font-size: 1.9em;
        }
    }
}

.page-subheading {
    font-size: 1.125rem;
    line-height: 1.28;
    padding-bottom: 2rem;
    padding-left: 15px;
    padding-right: 15px;
}

.page-heading-image {
    padding: 0;

    img {
        width: 100%;
    }
}

.button-yellow-round {
    border: none;
    border-radius: 1.5em;
    font-size: 1em;
    font-weight: bold;
    background-color: $color-primary;
    padding: 12px 36px;
    color: $color-black;

    &:hover {
        cursor: pointer;
        color: $color-black;
    }
}

.context-colreps {
    .button-yellow-round {
        background-color: $color-accent-colreps;
        color: $color-white-colreps;
    }
}

.context-mexreps {
    .button-yellow-round {
        background-color: $color-accent2-mexreps;
        color: $color-white-mexreps;
    }
}

.button-black-round {
    border: none;
    border-radius: 1.5em;
    font-size: 1em;
    font-weight: bold;
    background-color: $color-black;
    padding: 12px 36px;
    color: $color-white;

    &:hover {
        cursor: pointer;
        color: $color-primary;
    }
}

.border-bottom {
    border-bottom: $color-light-grey;
}

.border-top {
    border-top: $color-light-grey;
}

.text-center {
    text-align: center;
}

// news
.news-list-wrapper {
    .content {
        p {
            line-height: 26px;
        }
    }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.vdp-datepicker input {
    width: 100%;
    border: none;
    padding: 6px;
    outline: none;
}

a:hover {
    text-decoration: none;
}

.last-news-wrapper .entry .content p, .last-news-wrapper .entry h3 {
    @include breakpoint($breakpoint-mobile) {
        text-align: center;
        box-sizing: border-box;
        padding: 0 12px;
    }
}

.social-sharing {
  .social-sharing-wrapper {
    margin-top: 8px;
    width: 100%;
    padding: 16px;
    display: flex;
    justify-content: center;

    span {
      display: inline-block;
      margin: 0 12px;
      color: #fff;
      font-size: 2em;
    }
  }
}

a.attachment-pdf {
    color: $color-black;
    text-decoration: underline;
}

.private-router-link {
    cursor: pointer;
}

.slick-dots {
  bottom: 5px;

  li {
    button:before {
        content: "";
        width: 15px;
        height: 15px;
        border: 2px solid $color-white;
        border-radius: 50%;
        opacity: 1;
        box-shadow: 2px 2px 5px #333;
    }
  }

  li.slick-active {
      button:before {
          background-color: $color-white;
          opacity: 1;
      }
  }
}

.slick-dotted.slick-slider {
    margin-bottom: 0;
}

@import "tooltiptrainer";
</style>
