import Vue from 'vue'
import VueRouter from 'vue-router'
import jwt_decode from 'jwt-decode'

import ProfileVCardAppearance from '@/components/ProfileVCardAppearance'
import ProfileVCardLinks from '@/components/ProfileVCardLinks'
import ProfileVCardSocial from '@/components/ProfileVCardSocial'
import ProfileView from '@/components/ProfileView'
import Api from "@/components/services/Api";
import { settings } from '@/settings'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: '/', redirect: '/vcard-enlaces' },
    {
      path: '/vcard/:id',
      props: true,
      name: 'ProfileView',
      component: ProfileView,
    },
    {
      path: '/vcard-enlaces',
      name: 'ProfileVCardLinks',
      component: ProfileVCardLinks,
    },
    {
      path: '/vcard-apariencia',
      name: 'ProfileVCardAppearance',
      component: ProfileVCardAppearance,
    },
    {
      path: '/vcard-redessociales',
      name: 'ProfileVCardSocial',
      component: ProfileVCardSocial,
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  if (to.name === 'ProfileView') {
    next();
    return;
  }

  const jwtToken = Vue.$cookies.get('jwt_cookie');

  if (!jwtToken) {
    location.href = settings.iamLoginUrl;
    next();
    return;
  }

  try {
    await Api.checkJwtToken(jwtToken);
    const jwtData = jwt_decode(jwtToken);
    await Api.me(jwtData.email);
    store.commit("set_userEmail", jwtData.email);
  } catch (error) {
    location.href = settings.iamLoginUrl;
  }

  next();
});

export default router;
