<template lang="pug">
  include ProfileView.pug
</template>

<script>
import _ from 'underscore'
import { mapState } from 'vuex'
import Api from '@/components/services/Api'
import ProfileViewSocialIcons from '@/components/ProfileViewSocialIcons'
import ProfileBlock from '@/components/ProfileBlock'
import { settings } from '@/settings'

export default {
  name: 'ProfileView',
  components: {
    ProfileViewSocialIcons,
    ProfileBlock,
  },
  computed: _.extend(mapState(['token']), {
    gradient() {
      const color = this.vcard.vcard_color
      const colorEnd = this.vcard.vcard_color_end
      const styles = []

      styles.push(`background: ${color};`)
      styles.push(
        `background: -webkit-linear-gradient(to bottom, ${color}, ${colorEnd});`
      )
      styles.push(
        `background: linear-gradient(to bottom, ${color}, ${colorEnd});`
      )

      return styles.join(';')
    },
    backgroundColorFallbackWhite() {
      if (this.vcard.vcard_color) return this.vcard.vcard_color
      return '#fff'
    },
    isValid() {
      let valid = true

      if (_.isEmpty(this.form.name)) {
        valid = false
      }
      if (_.isEmpty(this.form.email)) {
        valid = false
      }
      if (_.isEmpty(this.form.mobile)) {
        valid = false
      }
      if (_.isEmpty(this.form.message)) {
        valid = false
      }
      if (_.isEmpty(this.form.country)) {
        valid = false
      }

      return valid
    },
  }),
  props: ['id'],
  data() {
    return {
      vcardId: null,
      vcard: {},
      items: [],
      loaded: false,
      sending: false,
      opened: null,
      trainer: {},
      profileImage: null,
      form: {
        name: '',
        email: '',
        mobile: '',
        message: '',
        country: '',
      },
    }
  },

  async created() {
    this.loadTrainer(this.id)
  },

  methods: {
    toggle(value) {
      if (value == this.opened) {
        this.opened = null
      } else {
        this.opened = value
      }
    },
    extractInstagramAlias(value) {
      if (!value || !_.isString(value)) return
      if (/^.+instagram\.com\/[^/]+$/.test(value)) {
        const url = _.last(value.split('?')[0].split('/'))
        this.instagram = url
      }
    },
    buildYoutube() {
      // https://www.youtube.com/watch?v=FRelb9_BKrg
      const youtubeUrl = this.vcard.vcard_data_youtube
      const urlSplit = _.first(_.last(youtubeUrl.split('?v=')).split('#'))
      this.youtubeIframe = `https://www.youtube.com/embed/${urlSplit}`
    },
    async sendContact() {
      this.sending = true
      const params = {
        name: this.form.name,
        email: this.form.email,
        message: this.form.message,
        mobile: this.form.mobile,
        country: this.form.country,
      }
      await Api.contactVcard(this.vcardId, params)
      this.sending = false

      // empty form
      this.form.name = ''
      this.form.email = ''
      this.form.message = ''
      this.form.mobile = ''
      this.form.country = ''
    },
    async loadTrainer(vcardName) {
      let min = 1;
      let max = 15;
      let randomIntegerImage = Math.floor(Math.random() * (max - min + 1) + min);
      let randomImage = '/static/img/avatar/avatar-perfil-' + randomIntegerImage + '.png'

      let vcardResponse = null;
      try {
        vcardResponse = await Api.getVcardProfile(vcardName);
      } catch {
        window.location.href = `${settings.iamLoginUrl}/404`;
      }

      const vcard = vcardResponse.data;
      this.vcardId = vcard.vcard_id;

      if (!vcard.allowed) {
        this.$router.push(`/e/${vcardName}/`);
      }
      if (vcard.vcard_items && vcard.vcard_items.length) {
        this.items = JSON.parse(vcard.vcard_items);
      }

      this.loaded = vcard.allowed;

      if (vcard.vcard_image) {
        this.profileImage = vcard.vcard_image;
      } else {
        this.profileImage = randomImage;
      }

      this.vcard = vcard;
      if (this.vcard.vcard_data_youtube) {
        this.buildYoutube();
      }
    },
  },
}
</script>

<style scoped lang="scss">
  @import './../variables';
  @import './ProfileView';
</style>
