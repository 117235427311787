<template lang="pug">
  include ProfileVCardAppearance.pug
</template>

<script>
import { first } from 'underscore'
import ColorBox from '@/components/vcard/ColorBox'
import { mapState } from 'vuex'
import Api from './services/Api'
import { settings } from '@/settings'

export default {
  name: 'ProfileVCardAppearance',
  components: {
    ColorBox,
  },
  props: ['id'],
  data() {
    return {
      vcardName: '',
      bio: '',
      title: '',
      loaded: false,
      slug: null,
      vcard: null,
      colors: [
        ['#891b5d', '#63d5c5'],
        ['#891b5d', '#891b5d'],
        ['#176999', '#176999'],
        ['#891b5d', '#176999'],
      ],
      selectedColor: null,
      iamUrl: settings.iamLoginUrl,
      avatarImage: null,
      vcard_image: null,
    }
  },
  watch: {
    async vcardName(newValue) {
      await Api.updateVcard(this.slug, { vcard_name: newValue }, this.token)
    },
    async title(newValue) {
      await Api.updateVcard(this.slug, { vcard_title: newValue }, this.token)
    },
    async bio(newValue) {
      await Api.updateVcard(this.slug, { vcard_bio: newValue }, this.token)
    },
  },
  computed: {
    ...mapState(['token', 'userEmail']),
    currentUrl: function () {
      return first(window.location.href.split('#'))
    },
    linkPreview: function () {
      return `${window.location.origin}/vcard/${this.vcardName}`;
    },
  },
  async created() {
    const _this = this

    await this.refreshMe()
    const vcardResponse = await Api.getVcard(this.slug)
    const vcard = vcardResponse.data
    const allowed = vcard.allowed

    if (!allowed) {
      this.$router.push({
        name: 'TrainerView',
        params: {
          id: this.slug,
        },
      })
    }

    this.vcard = vcard
    this.loaded = true

    // Update events
    this.$bus.on('vcard_update.color', async ({ color, color_end }) => {
      await Api.updateVcard(
        _this.slug,
        { vcard_color: color, vcard_color_end: color_end },
        _this.token
      )
      await _this.refreshMe()
    })
  },
  methods: {
    async uploadAvatar() {
      if (!this.avatarImage) {
        return;
      }
      await Api.uploadAvatar(this.slug, this.avatarImage);
      await this.refreshMe()
    },
    setAvatarImage(evt, index) {
      this.avatarImage = evt.target.files[0];
    },
    async refreshMe() {
      const me = await Api.me(this.userEmail);
      this.slug = me.data.vcard_id;

      const vcardResponse = await Api.getVcard(this.slug);
      const vcard = vcardResponse.data;
      const allowed = vcard.allowed;

      this.me = vcard;
      this.vcardName = this.me.vcard_name;
      this.title = this.me.vcard_title;
      this.bio = this.me.vcard_bio;
      this.vcard_image = this.me.vcard_image;

      if (!allowed) {
        this.$router.push('/');
      }
    },
    // async save() {
    //   this.saving = true
    //   // await Api.updateVcard(this.slug, data, this.token)
    //   this.saving = false
    // },
  },
}
</script>

<style scoped lang="scss">
  @import './../variables';
  @import './ProfileVCard';
  @import './ProfileVCardAppearance';
</style>
