<template lang="pug">
.option(
  @click="selectColor()"
  :class="(color == me.vcard_color && colorEnd == me.vcard_color_end) ? 'active' : ''"
  :style="gradient"
)
    .example-content
    .example-content
    .example-content
</template>

<script type="text/javascript">
export default {
  name: 'ColorBox',
  props: {
    color: {
      type: String,
      default: '',
    },
    colorEnd: {
      type: String,
      default: '',
    },
    me: {
      type: Object,
      default: function () {
        return {}
      },
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    gradient: function () {
      const styles = []

      styles.push(`background: ${this.color};`)
      styles.push(
        `background: -webkit-linear-gradient(to bottom, ${this.color}, ${this.colorEnd});`
      )
      styles.push(
        `background: linear-gradient(to bottom, ${this.color}, ${this.colorEnd});`
      )

      return styles.join(';')
    },
  },
  methods: {
    selectColor() {
      this.$bus.emit('vcard_update.color', {
        color: this.color,
        color_end: this.colorEnd,
      })
    },
  },
}
</script>

<style scoped lang="scss">
@import './../../variables';
@import './../ProfileVCardAppearance';
</style>
