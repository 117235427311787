import { render, staticRenderFns } from "./ProfileVCardLinks.vue?vue&type=template&id=36fd0cec&scoped=true&lang=pug&"
import script from "./ProfileVCardLinks.vue?vue&type=script&lang=js&"
export * from "./ProfileVCardLinks.vue?vue&type=script&lang=js&"
import style0 from "./ProfileVCardLinks.vue?vue&type=style&index=0&id=36fd0cec&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36fd0cec",
  null
  
)

export default component.exports