<template lang="pug">
  include ProfileVCardSocial.pug
</template>

<script>
import _ from 'underscore'
import { mapState } from 'vuex'
import Api from './services/Api'
import { settings } from '@/settings'

export default {
  name: 'ProfileVCardSocial',
  computed: mapState(['token', 'userEmail']),
  props: ['id'],
  data() {
    return {
      vcard: null,
      me: null,
      slug: null,
      vcardName: '',
      loaded: false,
      position: null,
      form: {
        email: "",
        facebook: "",
        twitter: "",
        instagram: "",
        youtube: "",
        linkedin: "",
        whatsapp: "",
      },
      iamUrl: settings.iamLoginUrl,
    }
  },
  async created() {
    const me = await Api.me(this.userEmail);
    this.slug = me.data.vcard_id;
    this.vcardName = me.data.vcard_name;

    const vcardResponse = await Api.getVcard(this.slug);
    const vcard = vcardResponse.data;
    const allowed = vcard.allowed;
    this.vcard = vcard;

    if (!allowed) {
      this.$router.push({
        name: "TrainerView",
        params: {
          id: this.slug,
        }
      })
    }

    this.form.email = this.vcard.vcard_email
    this.form.facebook = this.vcard.vcard_facebook
    this.form.twitter = this.vcard.vcard_twitter
    this.form.instagram = this.vcard.vcard_instagram
    this.form.youtube = this.vcard.vcard_youtube
    this.form.linkedin = this.vcard.vcard_linkedin
    this.form.whatsapp = this.vcard.vcard_whatsapp

    // Initial values
    const position = this.vcard.vcard_social_position
    if (_.isEmpty(position)) {
      this.position = "top"
    } else {
      this.position = this.vcard.vcard_social_position
    }

    this.loaded = true
  },
  watch: {
    async position(newValue) {
      await Api.updateVcard(this.slug, {vcard_social_position: newValue}, this.token)
    },
    'form.email': async function(newValue) {
      await Api.updateVcard(this.slug, {vcard_email: newValue}, this.token)
    },
    'form.facebook': async function(newValue) {
      await Api.updateVcard(this.slug, {vcard_facebook: newValue}, this.token)
    },
    'form.twitter': async function(newValue) {
      await Api.updateVcard(this.slug, {vcard_twitter: newValue}, this.token)
    },
    'form.instagram': async function(newValue) {
      await Api.updateVcard(this.slug, {vcard_instagram: newValue}, this.token)
    },
    'form.youtube': async function(newValue) {
      await Api.updateVcard(this.slug, {vcard_youtube: newValue}, this.token)
    },
    'form.linkedin': async function(newValue) {
      await Api.updateVcard(this.slug, {vcard_linkedin: newValue}, this.token)
    },
    'form.whatsapp': async function(newValue) {
      await Api.updateVcard(this.slug, {vcard_whatsapp: newValue}, this.token)
    },
  }
}
</script>

<style scoped lang="scss">
  @import './../variables';
  @import './ProfileVCard';
  @import './ProfileVCardSocial';
</style>
