<template lang="pug">
  include ProfileVCardLinks.pug
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import { mapState } from 'vuex'
import draggable from 'vuedraggable'
import Pubsub from 'pubsub-js'
import ProfileItem from '@/components/ProfileItem'
import Api from '@/components/services/Api'
import ExploreLinks from '@/components/explore_links/ExploreLinks'
import { settings } from '@/settings'

export default {
  name: 'ProfileVCardLinks',
  computed: mapState(['token', 'userEmail']),
  components: {
    draggable,
    ProfileItem,
    ExploreLinks,
  },
  props: ['id'],
  data() {
    return {
      vcardName: '',
      vcard_contact_form: false,
      vcard_form_mail_sending_email: "",
      modalOpened: false,
      items: [],
      drag: false,
      slug: null,
      loaded: false,
      vcard: null,
      iamUrl: settings.iamLoginUrl,
    }
  },

  async created() {
    const _this = this;
    const me = await Api.me(this.userEmail);
    this.slug = me.data.vcard_id;
    this.vcardName = me.data.vcard_name;

    const vcardResponse = await Api.getVcard(this.slug);
    this.vcard = vcardResponse.data;
    const allowed = this.vcard.allowed;

    this.vcard_contact_form = this.vcard.vcard_contact_form
    this.vcard_form_mail_sending_email = this.vcard.vcard_form_mail_sending_email

    if (!allowed) {
      this.$router.push('/');
    }

    if (this.vcard.vcard_items && this.vcard.vcard_items.length) {
      _this.items = JSON.parse(this.vcard.vcard_items)
    }

    this.loaded = true

    this.startListeners()
    this.loadItems()
  },

  methods: {
    loadItems() {
      // broadcast values (if items)
      if (this.items && this.items.length) {
        for (const item of this.items) {
          setTimeout(function () {
            Pubsub.publish('vcard.initial_data', item)
          }, 500)
        }
      }
    },
    startListeners() {
      Pubsub.subscribe('vcard.close_new_item', (event) => {
        this.modalOpened = false
      })

      Pubsub.subscribe('vcard.new_item', (event, data) => {
        this.modalOpened = false
        this.items.push({
          uuid: uuidv4(),
          type: data,
        })
      })

      // Data object update
      Pubsub.subscribe('vcard.delete_item', (event, uuid) => {
        this.items = this.items.filter((item) => {
          return item.uuid !== uuid
        })
      })

      Pubsub.subscribe('vcard.object_update', (event, item) => {
        for (const element of this.items) {
          if (element.uuid === item.uuid) {
            element.title = item.title
            element.value = item.value
            element.enabled = item.enabled
          }
        }
        this.sync()
      })
    },
    async sync() {
      const token = this.token;
      const vcard_items = JSON.stringify(this.items);
      await Api.updateVcard(this.slug, { vcard_items }, token);
    },
  },
  watch: {
    'vcard_contact_form': async function(newValue) {
      const token = this.token;
      const vcard_contact_form = (newValue) ? "enabled" : "disabled"
      await Api.updateVcard(this.slug, { vcard_contact_form }, token)
      this.vcard_contact_form = newValue
    },
    'vcard_form_mail_sending_email': async function(newValue) {
      const token = this.token;
      await Api.updateVcard(this.slug, { vcard_form_mail_sending_email: newValue }, token)
    }
  }
}
</script>

<style scoped lang="scss">
  @import './../variables';
  @import './ProfileVCard';
  @import './ProfileVCardLinks';
</style>
